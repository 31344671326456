import React from "react";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

import "../styles/ParallaxEl.scss";

export default function ParallaxEl({ style, children, speed }) {
  return (
    <Parallax speed={speed}>
      <div style={style}>{children}</div>
    </Parallax>
  );
}
