import "./assets/styles/App.scss";
import Hero from "./assets/components/Hero";
import SectionDivisor from "./assets/components/SectionDivisor";

import TrackVisibility from "react-on-screen";
import { ParallaxProvider } from "react-scroll-parallax";
import pic1 from "./assets/imgs/1.webp";
import pic2 from "./assets/imgs/6.webp";
import pic3 from "./assets/imgs/3.webp";
import pic4 from "./assets/imgs/4.webp";
import logoWords from "./assets/imgs/logo-words.png";

import FeaturesSection from "./assets/components/FeaturesSection";
import ReviewsSection from "./assets/components/ReviewsSection";
import VideoSection from "./assets/components/VideoSection";

import initReactFastclick from "react-fastclick";
import FAQSection from "./assets/components/FAQSection";
import Footer from "./assets/components/Footer";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import LandingHero from "./assets/components/LandingHero";
import { useEffect } from "react";

initReactFastclick();

const once = true;
const partialVisibility = true;


try {
  if (process.env.REACT_APP_ENV === "dev") {
    localStorage.setItem("umami.disabled", 1);
  }
} catch (err) {
  console.log(err)
}

try {
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault()
  })

} catch (err) {
  console.log(err);
}

function WebLayout({ hero }) {
  return (
    <>
      <ParallaxProvider>
        {hero}
        <TrackVisibility partialVisibility={partialVisibility} once={once}>
          <SectionDivisor pic={pic2} count="2">
            Somos la plataforma de referencia para runners <em>amateur</em> en Baleares.
          </SectionDivisor>
        </TrackVisibility>

        <ReviewsSection />


        <TrackVisibility partialVisibility={partialVisibility} once={once}>
          <SectionDivisor pic={pic1} count="3">
            Nos encargamos de que cada línea de meta que cruzas dure para toda la eternidad.
          </SectionDivisor>
        </TrackVisibility>

        <FAQSection />

        <Footer />
      </ParallaxProvider>
    </>
  );
}

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="*" element={<WebLayout hero={<LandingHero />} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
