import React from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion";
import Section from "./Section";
import { FiChevronRight } from "react-icons/fi";

import "../styles/FAQSection.scss";

export default function FAQSection() {
  return (
    <Section title="Preguntas frecuentes">
      <Accordion>
        <AccordionItem
          header={
            <div className="accordion-header">
              <span>¿Qué pasa si no he ganado ningún trofeo?</span>
              <FiChevronRight />
            </div>
          }
        >
          No pasa absolutamente nada. Se te añadirán también las medallas (de
          participación) a las carreras en las que hayas competido, indicando tu
          posición en el ranking de tu categoría.
          <br />
          <br />
          ¿Tampoco tienes medallas? ¡No importa! Cuando compitas en alguna
          carrera, aparecerá automáticamente.
          <br />
          <br />
          Mitali te acompaña desde tu primera carrera.
        </AccordionItem>

        <AccordionItem
          header={
            <div className="accordion-header">
              <span>¿Cómo funciona?</span>
              <FiChevronRight />
            </div>
          }
        >
          Nos basamos en el nombre completo de los atletas para relacionarlos
          con los resultados, por lo que pueden existir errores (que sobren o
          falten resultados).
          <br />
          <br />
          Actualizamos cada día los resultados, por lo que seguramente la
          carrera de hoy aparecerá a partir de mañana.
          <br />
          <br />
          Recogemos resultados de clasificaciones públicas en Internet. De todas formas,
          si alguien no quiere aparecer, que nos escriba un email y eliminaremos sus clasificaciones.
          </AccordionItem>

        <AccordionItem
          header={
            <div className="accordion-header">
              <span>¿Por qué lo hacéis gratis?</span>
              <div className="chevron">
                <FiChevronRight />
              </div>
            </div>
          }
        >
          Este proyecto ha sido creado por un estudiante de informática y
          aficionado al <em>running</em>. Es completamente gratis. Puedes usar
          toda la plataforma sin cedernos ningún dato (aunque te recomendamos
          crearte una cuenta, para poder personalizar tu estantería).
        </AccordionItem>

        <AccordionItem
          header={
            <div className="accordion-header">
              <span>Organizo competiciones, ¿puedo colaborar?</span>
              <div className="chevron">
                <FiChevronRight />
              </div>
            </div>
          }
        >
          Si eres innovador y organizas competiciones, quizá quieras entregar
          trofeos virtuales en tu próxima carrera.
          <br />
          <br />
          ¡Ponte en contacto con nosotros en{" "}
          <a href="mailto:info@mitali.app">info@mitali.app</a>!
        </AccordionItem>

        <AccordionItem
          header={
            <div className="accordion-header">
              <span>¿Cómo os puedo contactar?</span>
              <div className="chevron">
                <FiChevronRight />
              </div>
            </div>
          }
        >
          Para cualquier duda, mándanos un email a{" "}
          <a href="mailto:info@mitali.app">info@mitali.app</a>. ¡Estaremos
          encantados de ayudarte!
        </AccordionItem>
      </Accordion>
    </Section>
  );
}
