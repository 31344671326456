import React from "react";

import logo from "../imgs/logo.jpeg";
import "../styles/Footer.scss";

import { RiYoutubeFill, RiInstagramFill } from "react-icons/ri";

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer__company-info">
        <img src={logo} className="Footer__logo" alt="Mitali logo" />

        <div className="Footer__app-name-slogan">
          <h3 className="Footer__app-name">Mitali.app</h3>
          <h4 className="Footer__app-slogan">Saca tus logros a brillar</h4>
        </div>

        <div className="Footer__media">
          <a
            href="https://www.instagram.com/mitali.app/"
            target="_blank"
            rel="noreferrer"
            className="Footer__media-link"
            data-umami-event="instagram"
            data-umami-event-location="footer"
          >
            <RiInstagramFill />
          </a>

          <a
            href="https://www.youtube.com/@Mitaliapp"
            target="_blank"
            rel="noreferrer"
            className="Footer__media-link Footer__media-link--bigger"
            data-umami-event="youtube"
            data-umami-event-location="footer"
          >
            <RiYoutubeFill />
          </a>
        </div>
      </div>

      <ul className="Footer__links">
        <li className="Footer__link-li">
          <a href="mailto:info@mitali.app" className="Footer__link" data-umami-event="contact"
            data-umami-event-location="footer">
            Contacto
          </a>
        </li>
        {/* 
        <li className="Footer__link-li">
          <a href="/aboutus.html" className="Footer__link">
            Nosotros
          </a>
        </li> */}

        <li className="Footer__link-li">
          <a href="#faqs" className="Footer__link">
            FAQs
          </a>
        </li>

        <li className="Footer__link-li">
          <a href="/legal.html" className="Footer__link" data-umami-event="legal"
            data-umami-event-location="footer">
            Legal
          </a>
        </li>

        <li className="Footer__link-li">
          <a href="/privacy.html" className="Footer__link" data-umami-event="privacy-politics"
            data-umami-event-location="footer">
            Política de privacidad
          </a>
        </li>
      </ul>

      <div className="Footer__copyright">&copy; 2024 Mitali</div>
    </footer>
  );
}
