import React from "react";
import Centerer from "./Centerer";
import "../styles/CtaButton.scss";

export default function CtaButton(props) {
  const { children, primary, count } = props;

  return (
    <Centerer>
      <a
        className={`CtaButton ${primary && "CtaButton--primary"}`}
        href={`https://my.mitali.app/register?button=${count ?? false}`}
        {...props}
      >
        {children}
      </a>
    </Centerer>
  );
}
