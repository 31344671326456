import React from "react";
import CtaButton from "./CtaButton";

import "../styles/SectionDivisor.scss";

export default function SectionDivisor({
  children,
  pic,
  mainButton,
  optText,
  count,
  isVisible,
}) {
  console.log(isVisible)

  return (
    <section className="SectionDivisor">
      <h3
        className="SectionDivisor__text"
        style={{ opacity: isVisible ? "1" : "0", transition: "opacity 2s" }}
      >
        {children}
      </h3>

      <img
        alt="Mitali - La primera app para compartir tus trofeos y logros con todos tus seguidores"
        src={pic}
        className="SectionDivisor__bg-pic"
      />

      <CtaButton count={count} primary={mainButton} data-umami-event="create-account-button" data-umami-event-num={count}>
        {optText ?? "Crea tu cuenta"}
      </CtaButton>
    </section>
  );
}
