import React from "react";

export default function Centerer(props) {
  const vertical = props?.v ?? false;
  const horizontal = props?.v ?? true;

  return (
    <div
      className={`Centerer ${vertical && "Centerer--vertical"} ${
        horizontal && "Centerer--horizontal"
      }`}
    >
      {props?.children}
    </div>
  );
}
