import React from "react";
import "../styles/Trophy.scss";
import defaultPic from "../imgs/trophy-1.png";

export default function Trophy({
  pic = defaultPic,
  name,
  x,
  y,
  size = 5,
  style,
  fontSize = 0.65,
}) {
  return (
    <div className="Trophy" style={{ top: y, left: x, ...style }}>
      <img
        src={pic}
        style={{ width: `${size}rem` }}
        alt="Trophy pic"
        className="Trophy__pic"
      />
      <span style={{ fontSize: `${fontSize}rem` }} className="Trophy__name">
        {name}
      </span>
    </div>
  );
}
