import React from "react";
import Section from "./Section";
import UserReview from "./UserReview";

import mirPic from "../imgs/mir.webp";
import jaumePic from "../imgs/jaume.webp";
import bielPic from "../imgs/biel.webp";

export default function ReviewsSection() {
  return (
    <Section title="¿Qué dicen de nosotros?">
      <UserReview
        pic={mirPic}
        name="Joan Mir Mascaró"
        role="Ciclista de BH Team"
        profileLink="https://my.mitali.app/shelf/joan"
      >
        ¡Qué pasada poder ver todos los logros obtenidos! Con Mitali, los tengo
        ordenados y vistosos.
      </UserReview>

      <UserReview
        pic={jaumePic}
        name="Jaume Bagur"
        role="Corredor y triatleta"
        profileLink="https://my.mitali.app/shelf/jaumebagur"
      >
        Me encanta poder tener todos mis trofeos en un mismo lugar.
      </UserReview>

      <UserReview
        pic={bielPic}
        name="Biel Cardona"
        role="Corredor y triatleta"
        profileLink="https://my.mitali.app/shelf/gabrielcardonabosch"
      >
        ¡Es alucinante! Me encontró trofeos que ya ni me acordaba.
      </UserReview>
    </Section>
  );
}
