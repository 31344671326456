import React from "react";
import { FiExternalLink } from "react-icons/fi";

import "../styles/UserReview.scss"

export default function UserReview({ pic, name, role, children, profileLink }) {
    return (
        <div className="UserReview">
            <p className="UserReview__p">"{children}"</p>

            <div className="UserReview__user-info">
                <img className="UserReview__pic" src={pic} alt={`Fotografía de ${name} - Mitali`} />
                <div>
                    <h3 className="UserReview__name">{name}</h3>
                    <h4 className="UserReview__role">{role}</h4>
                </div>
            </div>

            <a href={profileLink} data-umami-event="user-review-link" data-umami-event-user={profileLink} target="_blank" rel="noreferrer" className="UserReview__profile-link"><FiExternalLink /></a>
        </div>
    )
}