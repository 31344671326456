import React from "react";

import "../styles/LandingHero.scss";
import ParallaxEl from "./ParallaxEl";

import trophy1 from "../imgs/trophies/1-bronze.png";
import trophy3Silver from "../imgs/trophies/3-silver.png";
import trophy3Gold from "../imgs/trophies/3-gold.png";
import trophy4 from "../imgs/trophies/4-bronze.png";
import trophy5 from "../imgs/trophies/5-gold.png";
import medal from "../imgs/medalla.png";

import Trophy from "./Trophy";
import ValidateTextFieldWarn from "./ValidateTextFieldWarn";

export default function LandingHero() {
  return (
    <section className="LandingHero">
      <header className="LandingHero__header">
        <h1 className="LandingHero__title">
          Somos la base de datos <br /> de running <br /> de Baleares
        </h1>

        <img
          src={trophy5}
          className="LandingHero__trophy"
          alt="Decoration Trophy"
        />
      </header>

      <p className="LandingHero__subtitle">
        Sabemos el sudor que hay detrás de cada carrera.
        <br />
        <br />
        Por eso, tienes todos tus resultados al alcance de un solo click.
        <br />
        <br />
        O también puedes buscar tus compañer@s de deporte.
      </p>

      <form action="https://my.mitali.app/demo" className="search-athlete">
        <ValidateTextFieldWarn
          InputProps={{
            className: "register-form__text-input",
          }}
          type="text"
          name="userFullName"
          data-umami-event="search-athlete-input"
          className="search-athlete__input"
          placeholder="Introduce el nombre del atleta"
          required
          variant="outlined"
          rule={(value) => value.trim().split(" ").length >= 3}
          errorMsg={
            "Para mayor precisión, escribe el nombre completo, con los dos apellidos"
          }
          onError={() => {}}
        />
        <button className="search-athlete__button" data-umami-event="landing-search-results" type="submit">
          Buscar resultados
        </button>
      </form>

      <div className="trophies-container">
        <ParallaxEl speed={1}>
          <Trophy
            pic={trophy3Gold}
            size={2.4}
            y={10}
            x={100}
            style={{ zIndex: 2 }}
            name="VII Sant Silvestre d'Alcúdia M40"
          />
        </ParallaxEl>
        <ParallaxEl speed={-0.25}>
          <Trophy
            pic={trophy1}
            size={3.75}
            y={110}
            x={-10}
            name="IV CrossClub D'Atletisme Badia De Palma"
          />
        </ParallaxEl>
        <Trophy
          pic={trophy4}
          size={2.3}
          y={-15}
          x={-60}
          style={{ zIndex: -1 }}
          name="VI Duatló Cross Montuïri"
        />
        <ParallaxEl speed={0.75}>
          <Trophy
            pic={trophy3Silver}
            size={1.5}
            style={{ right: "-1.2rem" }}
            y={100}
            name="Cursa per Muntanya Vall de Sóller"
          />
        </ParallaxEl>
        <ParallaxEl speed={0.1}>
          <Trophy
            pic={medal}
            fontSize={0.5}
            size={1.25}
            style={{ width: "4rem" }}
            y={10}
            x={50}
            name="II Mitja Marató Sa Pobla"
          />
        </ParallaxEl>
        <Trophy
          pic={medal}
          fontSize={0.5}
          size={1.25}
          style={{ width: "4rem", right: "1rem", zIndex: -1 }}
          y={-2}
          name="CxMuntanyes d'Artà 18k"
        />
        <Trophy
          pic={medal}
          fontSize={0.5}
          size={1.25}
          style={{ width: "4rem", right: "1rem", zIndex: -1 }}
          y={180}
          x={115}
          name="VI Mitja Marató Palma"
        />
      </div>
    </section>
  );
}
